import { useDispatch, useSelector } from "react-redux";
import { ReduxTypes } from "../type_models/ReduxTypes";
import GraphicView from "../components/layouts/GraphicView";
import MainTop from "../components/layouts/MainTop";
import TableView from "../components/layouts/TableView";
import { useEffect, useState } from "react";
import axios from "axios";
import NoInformation from "../components/layouts/NoInformation";
import Skeleton from "react-loading-skeleton";
import { changeStateValue } from "../redux/MainReducer";
import { MAIN_API } from "../MAIN_API";
import styles from "./Page.module.css";
function BankPage() {
  const tableVisible = useSelector(
    (state: ReduxTypes) => state.Data.tableVisible
  );
  const cashData = useSelector((state: ReduxTypes) => state.Data.cashData);

  const selectedCurrencyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );
  const selectedCompanyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCompanyID
  );
  const dayFilter = useSelector((state: ReduxTypes) => state.Data.dayFilter);

  const loading = useSelector((state: ReduxTypes) => state.Data.loading);

  const [bankData, setBankData] = useState([]);
  const [totalSumCurrency, setTotalSumCurrency] = useState<any>(null);

  const dispatch = useDispatch<any>();

  const getFilteredSum = (data: any[], selectedCurrencyID: string) => {
    console.log(data, "data");
    const latestDate = data.reduce((latest, item) => {
      return new Date(item.date) > new Date(latest) ? item.date : latest;
    }, data[0].date);
    console.log(latestDate, "latestDate");
    let totalSum = 0;

    for (const item of data) {
      if (item.date !== latestDate) continue;
      console.log(selectedCurrencyID, selectedCurrencyID);
      switch (selectedCurrencyID) {
        case "AZN":
          totalSum += item.sum;
          break;
        case "USD":
          totalSum += item.sumUSD;
          break;
        case "EUR":
          totalSum += item.sumEUR;
          break;
        case "RBL":
          totalSum += item.sumRBL;
          break;
        default:
          break;
      }
    }

    console.log(totalSum, "totalSum");
    return totalSum;
  };

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (cashData.length > 0) {
      const totalSum = getFilteredSum(
        cashData,
        selectedCurrencyID.currencyCode
      );
      const formattedSum =
        new Intl.NumberFormat("az-AZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalSum) +
        " " +
        selectedCurrencyID.currencyCode;

      setTotalSumCurrency(formattedSum);
      dispatch(
        changeStateValue({
          name: "cashTotal",
          value: formattedSum,
        })
      );
    }

    localStorage.setItem("cashData", JSON.stringify(bankData));
    dispatch(
      changeStateValue({
        name: "cashData",
        value: bankData,
      })
    );
  }, [selectedCurrencyID, selectedCompanyID, bankData, cashData]);

  useEffect(() => {
    const fetchBankData = async () => {
      dispatch(changeStateValue({ name: "loading", value: true }));
      setDataLoaded(false);

      try {
        const response = await axios.post(
          `${MAIN_API}/Bank`,
          {
            type: "Bank",
            period_type: dayFilter,
            number_of_days: 30,
            company_id: selectedCompanyID,
            customerID: "",
            currencyID: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy",
            },
          }
        );

        const responseData = response.data.response.data;

        setBankData(responseData);
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching bank data:", error);
      } finally {
        dispatch(changeStateValue({ name: "loading", value: false }));
      }
    };

    if (selectedCompanyID) {
      fetchBankData();
    }
  }, [selectedCompanyID, dayFilter]);
  return (
    <>
      {selectedCompanyID === "4e6aab03-bfed-11ed-a3dd-b47af12e823d" ? (
        ""
      ) : (
        <MainTop />
      )}

      <>
        {selectedCompanyID === "4e6aab03-bfed-11ed-a3dd-b47af12e823d" ? (
          <NoInformation />
        ) : loading && !dataLoaded ? ( // Skeleton yüklənmə prosesi
          <div className={styles.skeletonContainer}>
            <div className={styles.skeletonLeft}>
              <Skeleton
                circle={true}
                height={330}
                width={330}
                className={styles.circle_chart_skeleton}
              />
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} className={styles.skeletonText} />
              ))}
            </div>

            <div className={styles.skeletonBars}>
              {[
                540, 420, 230, 120, 290, 220, 430, 330, 500, 420, 440, 140, 540,
                520, 440, 220, 220, 430, 330, 500, 420, 440, 350, 430,
              ].map((height, index) => (
                <Skeleton
                  key={index}
                  className={styles.skeletonBar}
                  height={height}
                />
              ))}
            </div>
          </div>
        ) : dataLoaded && bankData.length === 0 ? ( // Data gəlsə, amma boş olsa "NoInformation"
          <NoInformation />
        ) : tableVisible ? (
          <TableView data={cashData} nameField="cash_register_name" />
        ) : (
          <GraphicView data={cashData} nameField="cash_register_name" />
        )}
      </>
    </>
  );
}

export default BankPage;
