export const initialState = {
  mobileDesign: false,
  navVisible: false,
  tableVisible: false,
  modalSelectVisible: false,
  modalSettingVisible: false,
  modalInfoVisible: false,
  headerVisible: false,
  minimize: false,
  selectedCompanyID: localStorage.getItem("selectedCompanyID"),
  selectedCurrency: JSON.parse(
    localStorage.getItem("selectedCurrency") ||
      '{"currencyCode": "AZN", "currencyId": "bc1e1898-6b2d-11ed-a3d9-b47af12e823d" }'
  ),
  selectedDonutLabel: "",
  cashData: JSON.parse(localStorage.getItem("cashData")) || [],
  kassaData: JSON.parse(localStorage.getItem("kassaData")) || [],
  debitorData: JSON.parse(localStorage.getItem("debitorData")) || [],
  cashTotal: 0,
  dayFilter: 1,
  loading: false,
};
