export const moneyFormat = (number)=>{
    if(number){
        const formatted = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
        return formatted;
    }else{
        return "";
    }
}
