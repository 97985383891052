import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStateValue } from "../../../../redux/MainReducer";
import style from "./style.module.css";

function Months() {
  const [activeBtn, setActiveBtn] = useState<number>(2);
  const dispatch = useDispatch<any>();

  const buttons = [
    { id: 0, label: "Son 30 gün" },
    { id: 2, label: "Cari ay" },
    { id: 1, label: "Keçən ay" },
  ];

  useEffect(() => {
    dispatch(changeStateValue({ name: "dayFilter", value: 2 }));
  }, [dispatch]);

  const handleClick = (id: number) => {
    dispatch(changeStateValue({ name: "dayFilter", value: id }));
    setActiveBtn(id);
  };


  return (
    <div className={style.months}>
      <ul className={style["months-list"]}>
        {buttons.map((button) => (
          <li
            key={button.id}
            onClick={() => handleClick(button.id)}
            className={activeBtn === button.id ? style["active-list"] : ""}
          >
            <span>{button.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Months;
