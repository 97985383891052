import Table from "../Table";
import style from "./style.module.css";

function TableView({ data, nameField }: any) {
  return (
    <div className={style["table-view"]}>
      <Table length={5} data={data} nameField={nameField} />
      <Table length={17} data={data} nameField={nameField} />
    </div>
  );
}

export default TableView;
