import { useSelector } from "react-redux";
import { TableProps } from "../../../type_models/PropsTypes";
import { ReduxTypes } from "../../../type_models/ReduxTypes";
import { CurrencyIcon } from "../../../utils/Currency";
import style from "./style.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeStateValue } from "../../../redux/MainReducer";
import Skeleton from "react-loading-skeleton";

function Table({ length, data, nameField }: any) {
  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const [formattedAllData, setFormattedAllData] = useState<any[]>([]);
  const [formattedBankData, setFormattedBankData] = useState<any[]>([]);
  const loading = useSelector((state: ReduxTypes) => state.Data.loading);

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat("az-AZ", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  const dispatch = useDispatch<any>();
  const selectedCurrencyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );

  const selectedDonutLabel = useSelector(
    (state: ReduxTypes) => state.Data.selectedDonutLabel
  );

  useEffect(() => {
    const filteredData = data.filter((item: any) =>
      selectedDonutLabel ? item[nameField] === selectedDonutLabel : true
    );

    if (!data.length) return;

    // Ən son tarixi tapırıq
    const lastDate = data
      .map((item: any) => new Date(item.date))
      .sort((a: Date, b: Date) => b.getTime() - a.getTime())[0];

    const lastDateFormatted = formatDate(lastDate.toISOString());

    // 🔹 Soldaki table üçün data (ən son tarixdə olanlar)
    const latestData = data.filter(
      (item: any) => formatDate(item.date) === lastDateFormatted
    );

    const groupedLatestData = Object.values(
      latestData.reduce((acc: any, item: any) => {
        const bankName = item[nameField];
        const currency = selectedCurrencyID?.currencyCode;

        const sum =
          currency === "USD"
            ? item.sumUSD
            : currency === "EUR"
            ? item.sumEUR
            : item.sum;

        if (!acc[bankName]) {
          acc[bankName] = { [nameField]: bankName, sum_currency: sum };
        }

        return acc;
      }, {})
    ).sort((a: any, b: any) => b.sum_currency - a.sum_currency);

    setFormattedAllData(groupedLatestData);

    const bankData = Object.values(
      filteredData.reduce((acc: any, item: any) => {
        const date = formatDate(item.date);
        const currency = selectedCurrencyID?.currencyCode;

        const sum =
          currency === "USD"
            ? item.sumUSD
            : currency === "EUR"
            ? item.sumEUR
            : item.sum;

        if (!acc[date]) {
          acc[date] = { date, sum_currency: 0 };
        }

        acc[date].sum_currency += sum;

        return acc;
      }, {})
    );

    setFormattedBankData(bankData);
  }, [selectedDonutLabel, data, selectedCurrencyID]);

  const handleRowClick = (name: string) => {
    dispatch(
      changeStateValue({
        name: "selectedDonutLabel",
        value: selectedDonutLabel === name ? "" : name,
      })
    );
  };

  return (
    <div className={style["table-card"]}>
      <div className={style["table-container"]}>
        {length === 5 ? (
          loading ? (
            [...Array(9)].map((_, index) => (
              <Skeleton
                key={index}
                height={23}
                style={{ marginBottom: "1px" }}
              />
            ))
          ) : (
            <table>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Kassa adı</th>
                  <th>Məbləğ</th>
                </tr>
              </thead>
              <tbody>
                {formattedAllData.map((d: any, index: any) => {
                  const isSelected =
                    selectedDonutLabel === d.cash_register_name;

                  return (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(d[nameField])}
                      style={{
                        cursor: "pointer",
                        backgroundColor: isSelected ? "#e6f7ff" : "transparent",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{d[nameField]}</td>
                      <td style={{ textAlign: "right", width: "160px" }}>
                        {formatNumber(d.sum_currency)}{" "}
                        <CurrencyIcon color={"#0D3558"} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        ) : loading ? (
          [...Array(15)].map((_, index) => (
            <Skeleton key={index} height={23} style={{ marginBottom: "1px" }} />
          ))
        ) : (
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Tarix</th>
                <th>Məbləğ</th>
              </tr>
            </thead>
            <tbody>
              {formattedBankData.length === 0 ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    Məlumat yoxdur
                  </td>
                </tr>
              ) : (
                formattedBankData.map((d: any, index: any) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{d.date}</td>
                    <td>
                      {formatNumber(d.sum_currency)}{" "}
                      <CurrencyIcon color={"#0D3558"} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Table;
