import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./auth/login/Login";
import HomePage from "./pages/HomePage";
import CashPage from "./pages/CashPage";
import CreditorPage from "./pages/CreditorPage";
import { useDispatch, useSelector } from "react-redux";
import { ReduxTypes } from "./type_models/ReduxTypes";
import MobilNavbar from "./components/mobile/MobilNavbar";
import MobilTabBar from "./components/mobile/MobilTabBar";
import MobilSelectModal from "./components/mobile/MobilSelectModal";
import MobilSettingModal from "./components/mobile/MobilSettingModal";
import Loader from "react-js-loader";
import Header from "./components/header/Header";
import { changeStateValue } from "./redux/MainReducer";
import ForgetPasswordPage from "./auth/ForgetPassword";
import NoInformationPage from "./pages/NoInformationPage";
import ModalInfo from "./components/layouts/ModalInfo";
import Sidebar from "./components/layouts/Sidebar";
import BankPage from "./pages/BankPage";
import DebitorPage from "./pages/DebitorPage";

function App() {
  const navVisible = useSelector((state: ReduxTypes) => state.Data.navVisible);
  const minimize = useSelector((state: ReduxTypes) => state.Data.minimize);
  const modalInfoVisible = useSelector(
    (state: ReduxTypes) => state.Data.modalInfoVisible
  );
  const [loader, setLoader] = useState<boolean>(true);
  const dispatch = useDispatch<any>();
  const [mainWidth, setMainWidth] = useState<string>("100%");

  function calculateMainWidth() {
    if (window.innerWidth <= 600) {
      return "100%";
    } else if (window.innerWidth <= 1296) {
      return minimize ? "calc(100% - 90px)" : "calc(100% - 250px)";
    } else {
      return minimize ? "calc(100% - 90px)" : "calc(100% - 325px)";
    }
  }

  useEffect(() => {
    setMainWidth(calculateMainWidth());
    const handleResize = () => {
      setMainWidth(calculateMainWidth());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minimize]);

  useEffect(() => {
    if (loader) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    setTimeout(() => {
      setLoader(false);
    }, 1000);

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [loader]);

  const handleCloseNavbar = () => {
    if (navVisible) {
      dispatch(changeStateValue({ name: "navVisible", value: false }));
    }
  };
  return (
    <div className="page">
      {window.innerWidth <= 600 ? <MobilNavbar /> : <Sidebar />}
      {window.innerWidth <= 600 && navVisible ? <Sidebar /> : null}
      <main style={{ width: mainWidth }} onClick={handleCloseNavbar}>
        <header>
          <Header />
        </header>
        <Routes>
          <Route path="/noinfo" element={<NoInformationPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/cash" element={<CashPage />} />
          <Route path="/bank" element={<BankPage />} />
          <Route path="/debitor" element={<DebitorPage />} />
          <Route path="/kreditor" element={<CashPage />} />
          <Route path="/walletremove" element={<CashPage />} />
          <Route path="/walletadd" element={<CreditorPage />} />
          <Route path="/warehouseresidue" element={<CreditorPage />} />
          <Route path="/production" element={<CreditorPage />} />
          <Route path="/sales" element={<CreditorPage />} />
          <Route path="/taxes" element={<CreditorPage />} />
          <Route path="/expenses" element={<CreditorPage />} />
          <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </main>
      {window.innerWidth <= 600 ? <MobilTabBar /> : null}
      <MobilSelectModal />
      <MobilSettingModal />
      {window.innerWidth <= 600 ? (
        <ModalInfo />
      ) : modalInfoVisible ? (
        <ModalInfo />
      ) : null}
      {loader ? (
        <div className="loader">
          <Loader
            type="box-rectangular"
            bgColor={"#0d2e4e"}
            color={"#0d2e4e"}
            title={"REPORTO"}
            size={80}
          />
        </div>
      ) : null}
    </div>
  );
}

export default App;
