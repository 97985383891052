import { ReduxTypes } from "../../type_models/ReduxTypes";
import { changeStateValue } from "../../redux/MainReducer";
import { useDispatch, useSelector } from "react-redux";
import MobileModalTop from "./MobileModalTop";
import {
  LogOutSvg,
  PersonIDCardSvg,
  CurrencyAZNSvg,
  CurrencyEurSvg,
  CurrencyUsdSvg,
} from "../../assets";
import styles from "./Mobile.module.css";
import img1 from "../../assets/images/az.png";
import img2 from "../../assets/images/us.png";
import img3 from "../../assets/images/rus.png";
import { useEffect, useState } from "react";

function MobilSettingModal() {
  const modalSettingVisible = useSelector(
    (state: ReduxTypes) => state.Data.modalSettingVisible
  );
  const selectedCurrencyData = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency?.currencyCode
  );
  const dispatch = useDispatch<any>();

  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("selectedCurrency")
      ? JSON.parse(localStorage.getItem("selectedCurrency") as string)
      : { currencyCode: "AZN", icon: <CurrencyAZNSvg color={"#0D3558"} /> }
  );

  const currencyIcons: Record<string, JSX.Element> = {
    AZN: <CurrencyAZNSvg color={"#0D3558"} />,
    USD: <CurrencyUsdSvg color={"#0D3558"} />,
    EUR: <CurrencyEurSvg color={"#0D3558"} />,
  };

  const handleCurrencyChange = (currencyCode: string) => {
    setSelectedCurrency({ currencyCode });

    localStorage.setItem(
      "selectedCurrency",
      JSON.stringify({ currencyCode }) // JSX.Element saxlamırıq
    );

    dispatch(
      changeStateValue({
        name: "selectedCurrency",
        value: { currencyCode },
      })
    );
    closeModal();
  };

  useEffect(() => {
    const savedCurrency = localStorage.getItem("selectedCurrency");

    if (savedCurrency) {
      const parsedCurrency = JSON.parse(savedCurrency);
      setSelectedCurrency({
        currencyCode: parsedCurrency.currencyCode,
      });

      dispatch(
        changeStateValue({
          name: "selectedCurrency",
          value: parsedCurrency,
        })
      );
    }
  }, []);

  const closeModal = () => {
    dispatch(
      changeStateValue({
        name: "modalSettingVisible",
        value: !modalSettingVisible,
      })
    );
  };

  return (
    <div className={modalSettingVisible ? "background" : ""}>
      <div
        className="fixed-bottom-modal"
        style={{ bottom: modalSettingVisible ? "0" : "-100%" }}
      >
        <div className="selection-head">
          <MobileModalTop closeModal={closeModal} />
          <div className="personal-logout">
            <div className="personal-info">
              <PersonIDCardSvg />
              <span className="group-name">Xan Group</span>
            </div>
            <button className="logout">
              <LogOutSvg color="#FAFAFA" />
            </button>
          </div>
        </div>

        <div className={styles.settingContainer}>
          <div className={styles.settingContainerItem}>
            <div className={styles.flagCircle}>
              <img src={img1} alt="azerbaijan flag" />
            </div>
            <p>AZ</p>
          </div>
          <div className={styles.settingContainerItem}>
            <div className={styles.flagCircle}>
              <img src={img2} alt="US flag" />
            </div>
            <p>EN</p>
          </div>
          <div className={styles.settingContainerItem}>
            <div className={styles.flagCircle}>
              <img src={img3} alt="rus flag" />
            </div>
            <p>RU</p>
          </div>
        </div>

        <div className={styles.settingContainer}>
          <div className={styles.settingContainerItem}>
            <div
              className={styles.flagCircle}
              onClick={() => handleCurrencyChange("AZE")}
            >
              <CurrencyAZNSvg color={"#0d3558"} />
            </div>
          </div>
          <div className={styles.settingContainerItem}>
            <div
              className={styles.flagCircle}
              onClick={() => handleCurrencyChange("USD")}
            >
              <CurrencyUsdSvg color={"#0d3558"} />
            </div>
          </div>
          <div className={styles.settingContainerItem}>
            <div
              className={styles.flagCircle}
              onClick={() => handleCurrencyChange("EUR")}
            >
              <CurrencyEurSvg color={"#0d3558"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobilSettingModal;
