import ChartComponent from "../components/chartSetting/ChartComponent";
import BarChartComponent from "../components/chartSetting/BarChartComponent";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Page.module.css";
import { useDispatch, useSelector } from "react-redux";
import { changeStateValue } from "../redux/MainReducer";
import { ReduxTypes } from "../type_models/ReduxTypes";
import { MAIN_API } from "../MAIN_API";
import axios from "axios";
import NoInformation from "../components/layouts/NoInformation";
function HomePage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const selectedCurrencyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );
  const selectedCompanyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCompanyID
  );
  const dayFilter = useSelector((state: ReduxTypes) => state.Data.dayFilter);

  const [cashData, setCashData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [debitorData, setDebitorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCompanyID) return;

      dispatch(changeStateValue({ name: "loading", value: true }));
      try {
        const [cashResponse, bankResponse, debitorResponse] = await Promise.all(
          [
            axios.post(
              `${MAIN_API}/Cash`,
              {
                type: "Cash",
                period_type: dayFilter,
                number_of_days: 30,
                company_id: selectedCompanyID,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy ",
                },
              }
            ),
            axios.post(
              `${MAIN_API}/Bank`,
              {
                type: "Bank",
                period_type: dayFilter,
                number_of_days: 30,
                company_id: selectedCompanyID,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy ",
                },
              }
            ),
            axios.post(
              `${MAIN_API}/Payble`,
              {
                type: "Payble",
                period_type: dayFilter,
                number_of_days: 30,
                company_id: selectedCompanyID,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy ",
                },
              }
            ),
          ]
        );

        setCashData(cashResponse.data.response.data);
        setBankData(bankResponse.data.response.data);
        setDebitorData(debitorResponse.data.response.data);
        // console.log(debitorResponse.data.response.data, "cs", "bn", "dn");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(changeStateValue({ name: "loading", value: false }));
      }
    };

    fetchData();
  }, [selectedCompanyID, dayFilter]);
  if (selectedCompanyID === "4e6aab03-bfed-11ed-a3dd-b47af12e823d") {
    return <NoInformation />;
  }

  return (
    <>
      <div className="charts">
        {loading ? (
          <>
            {[...Array(12)].map((_, index) => (
              <Skeleton
                key={index}
                className={styles.skeleton}
                borderRadius={8}
              />
            ))}
          </>
        ) : (
          <>
            <BarChartComponent
              backgroundColor="#249BF4"
              title="Kassa"
              data={cashData}
              fieldName="cash_register_name"
              path="/cash"
            />
            <BarChartComponent
              backgroundColor="#FF453D"
              title="Bank"
              data={bankData}
              fieldName="cash_register_name"
              path="/bank"
            />
            <BarChartComponent
              backgroundColor="#8AB438"
              title="Debitor"
              data={debitorData}
              fieldName="customer"
              path="/debitor"
            />
            <ChartComponent
              backgroundColor="#FEA445"
              title="Pul silinmə"
              // data={bankData}
            />
            <ChartComponent
              backgroundColor="#8B56FB"
              title="Pul mədaxil"
              // data={bankData}
            />
            <BarChartComponent
              backgroundColor="#159888"
              title="Anbar qalığı"
              data={bankData}
            />
            <ChartComponent
              backgroundColor="#EB3B6A"
              title="İstəhsal"
              // data={bankData}
            />
            <BarChartComponent
              backgroundColor="#249BF4"
              title="Alışlar"
              data={bankData}
            />
            <BarChartComponent
              backgroundColor="#DD3283"
              title="Kreditor"
              data={bankData}
            />
            <BarChartComponent
              backgroundColor="#8AB438"
              title="Vergilər  "
              data={bankData}
            />
            <ChartComponent
              backgroundColor="#DD3283"
              title="Xərclər"
              // data={bankData}
            />
            <BarChartComponent
              backgroundColor="#FEA445"
              title="Satışlar"
              data={bankData}
            />
          </>
        )}
      </div>
    </>
  );
}

export default HomePage;
