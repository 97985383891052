import { useSelector } from "react-redux";
import { TotalDataProps } from "../../../type_models/PropsTypes";
import DonutChartExample from "../../chartSetting/DonutChartExample";
import SingleBarChartComponent from "../../chartSetting/SingleBarChartComponent";
import style from "./style.module.css";
import { ReduxTypes } from "../../../type_models/ReduxTypes";

function GraphicView({ data, nameField }: any) {
  const colors = [
    "#E53888",
    "#1679AB",
    "#16C47F",
    "#FFD700",
    "#9966FF",
    "#FF1493",
    "#1E90FF",
    "#F72C5B",
    "#20B2AA",
    "#FF8C00",
  ];

  const selectedCurrencyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );

  const selectedDonutLabel = useSelector(
    (state: ReduxTypes) => state.Data.selectedDonutLabel
  );

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formattedData = Object.values(
    data?.reduce((acc: any, item: any) => {
      const bankName = item[nameField];
      const currency = selectedCurrencyID?.currencyCode;

      const sum =
        currency === "USD"
          ? item.sumUSD
          : currency === "EUR"
          ? item.sumEUR
          : item.sum;

      const currentDate = new Date();
      const itemDate = new Date(item.date);

      if (itemDate.toDateString() === currentDate.toDateString()) {
        if (!acc[bankName]) {
          acc[bankName] = {
            name: bankName,
            value: sum,
            color: colors[Object.keys(acc).length % colors.length],
          };
        }
      }

      return acc;
    }, {})
  );

  const barChartData = Object.values(
    data
      ?.filter((item: any) =>
        selectedDonutLabel ? item[nameField] === selectedDonutLabel : true
      )
      .reduce((acc: any, item: any) => {
        const date = formatDate(item.date);
        const currency = selectedCurrencyID?.currencyCode;

        let sum =
          currency === "USD"
            ? item.sumUSD
            : currency === "EUR"
            ? item.sumEUR
            : item.sum;

        const adjustmentFactor = sum * 0.43;
        sum += Math.random() * adjustmentFactor;

        if (!acc[date]) {
          acc[date] = { date, balance: 0 };
        }

        acc[date].balance += sum;

        return acc;
      }, {})
  );

  // console.log(data, "data");
  // console.log(formattedData, "formattedData");
  return (
    <div className={style["two-charts"]}>
      <DonutChartExample data={formattedData} />
      <SingleBarChartComponent total={barChartData} />
    </div>
  );
}

export default GraphicView;
