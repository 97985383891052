import { useSelector } from "react-redux";
import { ReduxTypes } from "../type_models/ReduxTypes";
import {
  CurrencyAZNSvg,
  CurrencyEurSvg,
  CurrencyRubleSvg,
  CurrencyUsdSvg,
} from "../assets";

export const CurrencyIcon = ({ color }: any) => {
  const selectedCurrency = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );

  switch (selectedCurrency?.currencyCode) {
    case "USD":
      return <CurrencyUsdSvg color={color} />;
    case "EUR":
      return <CurrencyEurSvg color={color} />;
    case "RUB":
      return <CurrencyRubleSvg color={color} />;
    default:
      return <CurrencyAZNSvg color={color} />;
  }
};
