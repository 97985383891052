import React, { useState, useEffect, useRef } from "react";
import {
  ArrowDownSvg,
  CurrencyAZNSvg,
  CurrencyEurSvg,
  CurrencyRubleSvg,
  CurrencyUsdSvg,
} from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { changeStateValue } from "../../../../redux/MainReducer";
import style from "./style.module.css";
import axios from "axios";
import { MAIN_API } from "../../../../MAIN_API";
import { ReduxTypes } from "../../../../type_models/ReduxTypes";

function Exchange() {
  const [dropVisible, setDropVisible] = useState<boolean>(false);

  const selectedCurrencyData = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );

  const x = useSelector((state: ReduxTypes) => state.Data);
  // console.log(x, "x");

  const [selectedCurrency, setSelectedCurrency] = useState<{
    value: string;
    icon: JSX.Element;
  }>({
    value: "AZN",
    icon: <CurrencyAZNSvg color={"#0D3558"} />,
  });

  const currencyNameMap: Record<string, string> = {
    "руб.": "RBL",
    AZN: "AZN",
    USD: "USD",
    EUR: "EUR",
  };

  const currencyIcons: Record<string, JSX.Element> = {
    AZN: <CurrencyAZNSvg color={"#0D3558"} />,
    USD: <CurrencyUsdSvg color={"#0D3558"} />,
    EUR: <CurrencyEurSvg color={"#0D3558"} />,
    RBL: <CurrencyRubleSvg color={"#0D3558"} />,
  };

  const getCurrencyIcon = (currencyName: string) => {
    const normalizedCurrency = currencyNameMap[currencyName];
    return (
      currencyIcons[normalizedCurrency] || <CurrencyAZNSvg color={"#0D3558"} />
    );
  };

  const [currencyData, setCurrencyData] = useState<any[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();

  const handleSelect = (item: any) => {
    console.log(item);
    const normalizedValue =
      currencyNameMap[item.Наименование] || item.Наименование;

    setSelectedCurrency({
      value: normalizedValue,
      icon: getCurrencyIcon(item.Наименование),
    });

    dispatch(
      changeStateValue({
        name: "selectedCurrency",
        value: {
          currencyCode: normalizedValue,
          currencyId: item.currencyID,
        },
      })
    );
    localStorage.setItem(
      "selectedCurrency",
      JSON.stringify({
        currencyCode: normalizedValue,
        currencyId: item.currencyID,
      })
    );

    setDropVisible(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          `${MAIN_API}/currencies`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy",
            },
          }
        );
        setCurrencyData(response.data.response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const savedCurrency = localStorage.getItem("selectedCurrency");

    if (savedCurrency) {
      const parsedCurrency = JSON.parse(savedCurrency);
      setSelectedCurrency({
        value: parsedCurrency?.currencyCode,
        icon: getCurrencyIcon(parsedCurrency?.currencyCode),
      });

      dispatch(
        changeStateValue({
          name: "selectedCurrency",
          value: parsedCurrency,
        })
      );
    }
  }, []);

  return (
    <div className={style.dropdown} ref={dropdownRef}>
      <button
        onClick={() => setDropVisible(!dropVisible)}
        className={`${style["dropdown-head"]} ${
          dropVisible ? style["dropdown-head_hover"] : ""
        }`}
      >
        <div className={style["dropdown-left"]}>
          <div className={style.currency}>
            <div className={style.currency}>{selectedCurrency.icon}</div>
          </div>
          <span>{selectedCurrencyData.currencyCode}</span>
        </div>
        <div className={style["dropdown-svg"]}>
          <ArrowDownSvg />
        </div>
      </button>
      <ul
        className={`${style["dropdown-content-currency"]} ${
          dropVisible ? style["visible"] : ""
        }`}
      >
        {currencyData?.slice(0, 3).map((item, index) => {
          const matchedIcon = getCurrencyIcon(item?.Наименование);
          const normalizedName =
            currencyNameMap[item?.Наименование] || item?.Наименование;
          return (
            <li key={index} onClick={() => handleSelect(item)}>
              <div className={style.currency}>{matchedIcon}</div>
              <span>{normalizedName}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Exchange;
