import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { changeStateValue } from "../../redux/MainReducer";
import { moneyFormat } from "../../actions/OtherFunctions";
import { CurrencyIcon } from "../../utils/Currency";
import { ReduxTypes } from "../../type_models/ReduxTypes";
import styles from "./Chart.module.css";
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChartExample = ({ data }: any) => {
  const dispatch = useDispatch<any>();
  const selectedDonutLabel = useSelector(
    (state: ReduxTypes) => state.Data.selectedDonutLabel
  );

  const handleSegmentClick = (elements: any) => {
    if (!elements.length) return;

    const clickedIndex = elements[0].index;
    const clickedLabel = chartData.labels[clickedIndex];

    const selectedEntry = data.find(
      (entry: any) => entry.name === clickedLabel
    );

    if (!selectedEntry) return;

    const selectedLabel = selectedEntry.name;
    const selectedColor = selectedEntry.color || "#249BF4";

    localStorage.setItem("entryColor", selectedColor);
    window.dispatchEvent(new Event("colorChange"));

    dispatch(
      changeStateValue({
        name: "selectedDonutLabel",
        value: selectedLabel,
      })
    );
  };

  const handleLabelClick = (entry: any) => {
    console.log(entry);
    const isSelected = selectedDonutLabel === entry.name;
    const newLabel = isSelected ? "" : entry.name;
    const newColor = isSelected ? "" : entry.color;

    dispatch(
      changeStateValue({
        name: "selectedDonutLabel",
        value: newLabel,
      })
    );

    localStorage.setItem("entryColor", newColor);
    window.dispatchEvent(new Event("colorChange"));
  };

  const minSize = 8;
  const total = data.reduce((sum: any, entry: any) => sum + entry.value, 0);

  const chartData = {
    labels: data.map((entry: any) => entry.name),
    datasets: [
      {
        data: data.map((entry: any) =>
          (entry.value / total) * 100 < minSize
            ? (total * minSize) / 100
            : entry.value
        ),
        backgroundColor: data.map((entry: any) =>
          selectedDonutLabel === "" || selectedDonutLabel === entry.name
            ? entry.color
            : `${entry.color}66`
        ),
        // hoverBackgroundColor: data.map(
        //   (entry: any) =>
        //     selectedDonutLabel === ""
        //       ? darkenColor(entry.color, 0.11) // Heç nə seçilməyibsə, orijinal qalsın
        //       : selectedDonutLabel === entry.name
        //       ? darkenColor(entry.color, 0.2) // Seçilən hoverdə daha da tünd olsun
        //       : darkenColor(entry.color, 0.11) // Bozumtullar hoverdə bir az tündləşsin
        // ),
        borderWidth: 1,
      },
    ],
  };
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 20,
          weight: "bold",
        },
        bodyFont: {
          size: 18,
          weight: "bold",
        },

        callbacks: {
          label: function (tooltipItem: any) {
            return `${moneyFormat(tooltipItem.raw)} `;
          },
        },
      },
    },
    onClick: (_: any, elements: any) => handleSegmentClick(elements),
  };

  useEffect(() => {
    if (selectedDonutLabel) {
      const selectedEntry = data.find(
        (entry: any) => entry.name === selectedDonutLabel
      );
      if (selectedEntry) {
        localStorage.setItem("entryColor", selectedEntry.color || "#249BF4");
        window.dispatchEvent(new Event("colorChange"));
      }
    }
  }, []);

  return (
    <div
      className="chart-card-circle chart-container"
      style={{ position: "relative", backgroundColor: "white", width: "100%" }}
    >
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Pie data={chartData} options={options} className={styles.chart} />
      </div>

      <div
        style={{ maxHeight: "202px", overflowY: "auto" }}
        className="donut-scroll"
      >
        {data
          .sort((a: any, b: any) => b.value - a.value)
          .map((entry: any, index: number) => {
            const formattedSum = moneyFormat(entry.value);
            const isSelected = selectedDonutLabel === entry.name;
            return (
              <div
                key={index}
                className={styles["donut-line"]}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 8,
                  cursor: "pointer",
                  backgroundColor: isSelected ? "#EDEFF1" : "transparent",
                  color: isSelected ? entry.color : "rgba(0, 0, 0, 0.6)",
                  transition: "all .3s ease",
                }}
                onClick={() => handleLabelClick(entry)}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#f5f7fa";
                  e.currentTarget.style.color = entry.color;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = isSelected
                    ? "#EDEFF1"
                    : "transparent";
                  e.currentTarget.style.color = isSelected
                    ? entry.color
                    : "rgba(0, 0, 0, 0.6)";
                }}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: entry.color,
                    marginRight: 10,
                    borderRadius: "2px",
                  }}
                  className={styles["donut-entry-container"]}
                ></div>
                <span className={styles["donut-entry"]}>{entry.name}</span>

                <span
                  style={{
                    marginLeft: "auto",
                    maxWidth: "95x",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  {formattedSum} <CurrencyIcon color={"#0D3558"} />
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DonutChartExample;
