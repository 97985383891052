import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxTypes } from "../../../../type_models/ReduxTypes";
import { changeStateValue } from "../../../../redux/MainReducer";
import { ArrowDownSvg, InfoCircleSvg } from "../../../../assets";
import style from "./style.module.css";
import { MAIN_API } from "../../../../MAIN_API";
import axios from "axios";

function Companies() {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [companies, setCompanies] = useState<any[]>([]);
  const modalInfoVisible = useSelector(
    (state: ReduxTypes) => state.Data.modalInfoVisible
  );
  const dispatch = useDispatch<any>();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedCompanyId = useSelector(
    (state: ReduxTypes) => state.Data.selectedCompanyID
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          `${MAIN_API}/Companies`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy",
            },
          }
        );
        // console.log("API Response:", response.data.response.data);
        setCompanies(response.data.response.data);

        const savedCompany = localStorage.getItem("selectedCompany");
        if (savedCompany) {
          const parsedCompany = JSON.parse(savedCompany);
          setSelectedCompany(parsedCompany);

          if (!selectedCompanyId) {
            dispatch(
              changeStateValue({
                name: "selectedCompanyID",
                value: parsedCompany.CompanyID,
              })
            );
          }
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleSelect = (company: any) => {
    console.log(company);
    setSelectedCompany(company);
    dispatch(
      changeStateValue({
        name: "selectedCompanyID",
        value: company?.CompanyID,
      })
    );
    localStorage.setItem("selectedCompany", JSON.stringify(company));
    setDropVisible(false);
  };

  useEffect(() => {
    if (selectedCompanyId && companies.length > 0) {
      const selected = companies.find((c) => c.CompanyID === selectedCompanyId);
      if (selected) {
        setSelectedCompany(selected);
        localStorage.setItem("selectedCompany", JSON.stringify(selected));
      }
    }
  }, [selectedCompanyId, companies]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className={style.dropdown} ref={dropdownRef}>
      <div
        className={`${style["dropdown-head"]} ${
          dropVisible ? style["dropdown-head_hover"] : ""
        }`}
        onClick={() => setDropVisible(!dropVisible)}
      >
        <div className={style["dropdown-left"]}>
          <button
            className={style["info-btn"]}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                changeStateValue({
                  name: "modalInfoVisible",
                  value: !modalInfoVisible,
                })
              );
            }}
          >
            <InfoCircleSvg />
          </button>
          <div className={style["dropdown-left"]}>
            <span>
              {" "}
              {truncateText(
                selectedCompany?.Наименование || "Şirkət seçin",
                20
              )}
            </span>
            <div className={style["dropdown-svg"]}>
              <ArrowDownSvg />
            </div>
          </div>
        </div>
      </div>
      <ul
        className={`${style["dropdown-content"]} ${
          dropVisible ? style["visible"] : ""
        }`}
      >
        {companies.map((item, index) => (
          <li key={index} onClick={() => handleSelect(item)}>
            <span>{item?.Наименование}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Companies;
