import React from 'react'

function CurrencyEur({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={8}
            height={12}
            fill="none"
        >
            <path
                fill={color}
                d="M5.482.934c.467 0 .891.047 1.274.14.392.093.77.233 1.134.42l-.672 1.582a6.468 6.468 0 0 0-.882-.322 2.865 2.865 0 0 0-.854-.126c-.28 0-.537.042-.77.126a1.7 1.7 0 0 0-.602.35 2.044 2.044 0 0 0-.448.588 3.193 3.193 0 0 0-.252.812h2.758v1.204H3.312c0 .037-.005.089-.014.154v.42c0 .065.005.135.014.21h2.422V7.71h-2.31c.075.364.205.672.392.924.196.243.443.43.742.56.308.121.663.182 1.064.182.355 0 .686-.033.994-.098a4.89 4.89 0 0 0 .882-.294v1.75c-.261.13-.555.229-.882.294-.317.075-.69.112-1.12.112-.728 0-1.377-.13-1.946-.392a3.674 3.674 0 0 1-1.414-1.176c-.373-.513-.625-1.134-.756-1.862H.442V6.492h.812a2.428 2.428 0 0 1-.028-.196v-.434c0-.065.005-.117.014-.154H.442V4.504h.91c.121-.747.369-1.386.742-1.918s.85-.938 1.428-1.218c.579-.29 1.232-.434 1.96-.434Z"
            />
        </svg>
    )
}

export default CurrencyEur