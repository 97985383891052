import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { BarChartProps } from "../../type_models/PropsTypes";
import { ReduxTypes } from "../../type_models/ReduxTypes";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyIcon } from "../../utils/Currency";
import axios from "axios";
import { MAIN_API } from "../../MAIN_API";
import { changeStateValue } from "../../redux/MainReducer";

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: "1", value: 30 },
  { name: "2", value: 70 },
  { name: "3", value: 20 },
  { name: "4", value: 90 },
  { name: "5", value: 50 },
  { name: "6", value: 40 },
  { name: "7", value: 60 },
  { name: "8", value: 20 },
  { name: "9", value: 80 },
  { name: "10", value: 60 },
];

const xAxisLabels = data.map((item) => item.name);

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;
    console.log(payload, "payload");

    const formattedSum =
      new Intl.NumberFormat("az-AZ", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(balance) + " ";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: "12px 12px 0 0",
            padding: "7px",
            paddingBottom: "2px",
            color: "#0D3558",
            fontSize: "9px",
          }}
        >
          <strong>Date: </strong> {date}
        </div>
        <div
          style={{
            background: "#fff",
            borderRadius: "0 0 12px 12px",
            color: "#0D3558",
            padding: "7px",
            paddingTop: "1px",
            boxShadow: "0px 4px 10px 0px rgba(14, 73, 113, 0.10)",
            fontSize: "9px",
          }}
        >
          <strong>Balans: </strong> {formattedSum} ₼
        </div>
      </div>
    );
  }

  return null;
};

function BarChartComponent(props: BarChartProps) {
  const [fontSize, setFontSize] = useState("16px");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize("16px");
      } else {
        setFontSize("16px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dayFilter = useSelector((state: ReduxTypes) => state.Data.dayFilter);
  const cashData = useSelector((state: ReduxTypes) => state.Data.cashData);
  const selectedCurrencyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCurrency
  );
  const selectedCompanyID = useSelector(
    (state: ReduxTypes) => state.Data.selectedCompanyID
  );

  const [totalSumCurrency, setTotalSumCurrency] = useState<any>(null);
  const [bankData, setBankData] = useState([]);

  const dispatch = useDispatch<any>();

  const getFilteredSum = (data: any[], selectedCurrencyID: string) => {
    // console.log(data, "data");
    const latestDate = data.reduce((latest, item) => {
      return new Date(item.date) > new Date(latest) ? item.date : latest;
    }, data[0].date);
    // console.log(latestDate, "latestDate");
    let totalSum = 0;

    for (const item of data) {
      if (item.date !== latestDate) continue;
      console.log(selectedCurrencyID, selectedCurrencyID);
      switch (selectedCurrencyID) {
        case "AZN":
          totalSum += item.sum;
          break;
        case "USD":
          totalSum += item.sumUSD;
          break;
        case "EUR":
          totalSum += item.sumEUR;
          break;
        case "RBL":
          totalSum += item.sumRBL;
          break;
        default:
          break;
      }
    }

    // console.log(totalSum, "totalSum");
    return totalSum;
  };

  // console.log(totalSumCurrency, "totalSumCurrency");

  useEffect(() => {
    if (props?.data?.length > 0) {
      const totalSum = getFilteredSum(
        props.data,
        selectedCurrencyID.currencyCode
      );
      const formattedSum =
        new Intl.NumberFormat("az-AZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalSum) +
        " " +
        selectedCurrencyID.currencyCode;

      setTotalSumCurrency(formattedSum);
    }
  }, [selectedCurrencyID, selectedCompanyID, bankData]);

  useEffect(() => {
    const fetchBankData = async () => {
      dispatch(changeStateValue({ name: "loading", value: true }));

      try {
        const response = await axios.post(
          `${MAIN_API}/Bank`,
          {
            type: "Bank",
            period_type: dayFilter,
            number_of_days: 30,
            company_id: selectedCompanyID,
            customerID: "",
            currencyID: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic SFRUUDpTYWxpYW5Gb3JldmVy ",
            },
          }
        );

        setBankData(response.data.response.data);
      } catch (error) {
        console.error("Error fetching bank data:", error);
      } finally {
        dispatch(changeStateValue({ name: "loading", value: false }));
      }
    };

    if (selectedCompanyID) {
      fetchBankData();
    }
  }, [selectedCompanyID, selectedCurrencyID, dayFilter]);

  const selectedDonutLabel = useSelector(
    (state: ReduxTypes) => state.Data.selectedDonutLabel
  );

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const barChartData = Object.values(
    (props.data || [])
      .filter((item: any) =>
        selectedDonutLabel ? item[props.fieldName] === selectedDonutLabel : true
      )
      .reduce((acc: any, item: any) => {
        const date = formatDate(item.date);
        const currency = selectedCurrencyID?.currencyCode;

        let sum =
          currency === "USD"
            ? item.sumUSD
            : currency === "EUR"
            ? item.sumEUR
            : item.sum;

        const adjustmentFactor = sum * 0.43;
        sum += Math.random() * adjustmentFactor;

        if (!acc[date]) {
          acc[date] = { date, balance: 0 };
        }

        acc[date].balance += sum;

        return acc;
      }, {})
  );

  // console.log(barChartData, "barchart");

  const pageUrl = props.path?.toLowerCase() || "";
  // navigate(pageUrl);
  return (
    <div
      style={{ backgroundColor: props.backgroundColor, cursor: "pointer" }}
      className="chart-card chart-card-shadow"
      onClick={() => {
        const pageUrl = props.path?.toLowerCase() || "";
        navigate(pageUrl);
      }}
    >
      <div className="chart-info">
        <h3 style={{ fontSize: fontSize, marginBottom: "8px" }}>
          {props.title}
        </h3>
        <p>
          {totalSumCurrency} <CurrencyIcon color={"#fff"} />
        </p>
      </div>
      <ResponsiveContainer width="100%" maxHeight={250} height="80%">
        <BarChart data={barChartData}>
          <CartesianGrid
            strokeDasharray="1 1"
            vertical={false}
            strokeWidth={1}
            stroke={window.innerWidth <= 600 ? "none" : "#fff"}
          />
          <XAxis
            dataKey="date"
            tick={window.innerWidth <= 600 ? false : { fill: "#fff" }}
            stroke={window.innerWidth <= 600 ? "none" : "#fff"}
            fontSize={fontSize}
            height={window.innerWidth <= 600 ? 10 : 30}
            ticks={xAxisLabels}
          />
          {/* <YAxis
            tick={window.innerWidth <= 600 ? false : { fill: "#fff" }}
            stroke="none"
            width={window.innerWidth <= 600 ? 0 : 30}
            fontSize={fontSize}
            tickFormatter={(value) =>
              value.toLocaleString("az-AZ", { minimumFractionDigits: 0 })
            }
          /> */}
          <Bar
            dataKey="balance"
            fill="#ffffff"
            barSize={5}
            style={{ transition: "all .3s ease" }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "rgba(255, 255, 255, 0.2)" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarChartComponent;
