import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DiagramSvg, MenuSvg } from "../../../assets";
import { ReduxTypes } from "../../../type_models/ReduxTypes";
import { changeStateValue } from "../../../redux/MainReducer";
import style from "./style.module.css";
import Skeleton from "react-loading-skeleton";

function MainTop({ totalSumCurrency }: any) {
  const [changeBtn, setChangeBtn] = useState<boolean>(false);
  const tableVisible = useSelector(
    (state: ReduxTypes) => state.Data.tableVisible
  );
  const dispatch = useDispatch<any>();
  const loading = useSelector((state: ReduxTypes) => state.Data.loading);
  const cashTotal = useSelector((state: ReduxTypes) => state.Data.cashTotal);

  const handleShowAll = () => {
    dispatch(
      changeStateValue({
        name: "selectedDonutLabel",
        value: "",
      })
    );

    localStorage.removeItem("entryColor");
    window.dispatchEvent(new Event("colorChange"));
  };

  return (
    <div className={style["main-top"]}>
      {loading ? (
        <Skeleton width={220} height={24} />
      ) : (
        <>
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            Son qalıq:{" "}
            <div className={style.totalCashBtn} onClick={handleShowAll}>
              {cashTotal}
            </div>
          </h3>
        </>
      )}

      <button
        onClick={() =>
          dispatch(
            changeStateValue({ name: "tableVisible", value: !tableVisible }),
            setChangeBtn(!changeBtn)
          )
        }
      >
        {changeBtn ? (
          <>
            <span className={style["button-text"]}>Qrafik görünüşü</span>
            <DiagramSvg />
          </>
        ) : (
          <>
            <span className={style["button-text"]}>Cədvəl görünüşü</span>
            <MenuSvg color="#FAFAFA" />
          </>
        )}
      </button>
    </div>
  );
}

export default MainTop;
