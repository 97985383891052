import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter } from "react-router-dom";
import "./index.css";
import "./components/css/login.css";
import "./components/css/chart.css";
import "./components/css/mobile.css";
import "./components/css/responsive.css";
import { Provider } from "react-redux";
import { setupStore } from "./redux/store";
import "react-loading-skeleton/dist/skeleton.css";
const store = setupStore();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);
